exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-blog-entry-jsx": () => import("./../../../src/templates/blog-entry.jsx" /* webpackChunkName: "component---src-templates-blog-entry-jsx" */),
  "component---src-templates-wiki-entry-jsx": () => import("./../../../src/templates/wiki-entry.jsx" /* webpackChunkName: "component---src-templates-wiki-entry-jsx" */),
  "component---src-templates-wiki-index-jsx": () => import("./../../../src/templates/wiki-index.jsx" /* webpackChunkName: "component---src-templates-wiki-index-jsx" */)
}

